import { routes } from '@seedplanner/shared-web';
import React from 'react';
import Image from './Image';

export function LogoCircle() {
  return (
    <Image
      sx={{
        marginRight: 'auto',
        marginLeft: 'auto',
        borderRadius: '50%',
        width: '35%',
        height: '35%',
      }}
      width={256}
      height={256}
      imageUrl={routes.makePublic('/logo-256x256.jpg')}
      altText="Microgreen Manager Logo"
    />
  );
}
